@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  color: white;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

main {
  display: flex;
  flex-direction: column;
  width: 15rem;
  margin: auto;
}

main div {
  display: flex;
  flex-direction: column;
}

input {
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px solid cornflowerblue;
  margin-top: 0.25rem;
  font-family: inherit;
}

button {
  padding: 0.5rem;
  border-radius: 4px;
  margin-top: 0.5rem;
  background-color: cornflowerblue;
  outline: none;
  color: white;
  font-weight: bold;
  font-family: inherit;
  transition: opacity 300ms ease-in-out;
  border: 1px solid cornflowerblue;
  cursor: pointer;
}

button:hover {
  opacity: 0.7;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

